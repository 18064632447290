@font-face {
  font-family: 'map-composer';
  src: url('../font/map-composer.eot?41716266');
  src: url('../font/map-composer.eot?41716266#iefix') format('embedded-opentype'),
       url('../font/map-composer.woff2?41716266') format('woff2'),
       url('../font/map-composer.woff?41716266') format('woff'),
       url('../font/map-composer.ttf?41716266') format('truetype'),
       url('../font/map-composer.svg?41716266#map-composer') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'map-composer';
    src: url('../font/map-composer.svg?41716266#map-composer') format('svg');
  }
}
*/
 
 [class^="mp-"]:before, [class*=" mp-"]:before {
  font-family: "map-composer";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.mp-gas-station:before { content: '\e800'; } /* '' */
.mp-environment:before { content: '\e801'; } /* '' */
.mp-hospital-b:before { content: '\e802'; } /* '' */
.mp-pharmacy-2:before { content: '\e803'; } /* '' */
.mp-police-station-:before { content: '\e804'; } /* '' */
.mp-police-station-b:before { content: '\e805'; } /* '' */
.mp-postal-1:before { content: '\e806'; } /* '' */
.mp-school:before { content: '\e807'; } /* '' */
.mp-shop:before { content: '\e808'; } /* '' */
.mp-religious-school:before { content: '\e809'; } /* '' */
.mp-religious-school-1:before { content: '\e80a'; } /* '' */
.mp-pharmacy:before { content: '\e80b'; } /* '' */
.mp-print:before { content: '\e80c'; } /* '' */
.mp-print-1:before { content: '\e80d'; } /* '' */
.mp-college-15:before { content: '\e80e'; } /* '' */
.mp-police-15:before { content: '\e80f'; } /* '' */
.mp-place-of-worship-15:before { content: '\e810'; } /* '' */
.mp-post-15:before { content: '\e811'; } /* '' */
.mp-police:before { content: '\e812'; } /* '' */
.mp-market:before { content: '\e813'; } /* '' */
.mp-prison-15:before { content: '\e814'; } /* '' */
.mp-town-hall:before { content: '\e815'; } /* '' */
.mp-fuel:before { content: '\e816'; } /* '' */
.mp-hospital-15:before { content: '\e817'; } /* '' */
.mp-pharmacy-15:before { content: '\e818'; } /* '' */
.mp-prison:before { content: '\e819'; } /* '' */
.mp-download:before { content: '\e81a'; } /* '' */
.mp-map-1:before { content: '\e81b'; } /* '' */
.mp-search:before { content: '\e81c'; } /* '' */
.mp-plus:before { content: '\e81d'; } /* '' */
.mp-doc:before { content: '\e81e'; } /* '' */
.mp-print-3:before { content: '\e81f'; } /* '' */
.mp-laptop:before { content: '\e820'; } /* '' */
.mp-hospital:before { content: '\e821'; } /* '' */
.mp-worship-house:before { content: '\e822'; } /* '' */
.mp-building:before { content: '\e823'; } /* '' */
.mp-postal:before { content: '\e824'; } /* '' */
.mp-globe:before { content: '\e825'; } /* '' */
.mp-right:before { content: '\e826'; } /* '' */
.mp-left:before { content: '\e827'; } /* '' */
.mp-up:before { content: '\e828'; } /* '' */
.mp-down:before { content: '\e829'; } /* '' */
.mp-left-open:before { content: '\e82a'; } /* '' */
.mp-down-open:before { content: '\e82b'; } /* '' */
.mp-right-open:before { content: '\e82c'; } /* '' */
.mp-up-open:before { content: '\e82d'; } /* '' */
.mp-right-1:before { content: '\e82e'; } /* '' */
.mp-left-1:before { content: '\e82f'; } /* '' */
.mp-folder:before { content: '\e830'; } /* '' */
.mp-folder-1:before { content: '\e831'; } /* '' */
.mp-user-add:before { content: '\e832'; } /* '' */
.mp-user:before { content: '\e833'; } /* '' */
.mp-user-outline:before { content: '\e834'; } /* '' */
.mp-down-dir:before { content: '\e835'; } /* '' */
.mp-up-dir:before { content: '\e836'; } /* '' */
.mp-left-dir:before { content: '\e837'; } /* '' */
.mp-spin5:before { content: '\e838'; } /* '' */
.mp-spin6:before { content: '\e839'; } /* '' */
.mp-right-dir:before { content: '\e83a'; } /* '' */
.mp-home:before { content: '\e83b'; } /* '' */
.mp-loop:before { content: '\e83c'; } /* '' */
.mp-arrows-cw:before { content: '\e83d'; } /* '' */
.mp-floppy:before { content: '\e83e'; } /* '' */
.mp-floppy-1:before { content: '\e83f'; } /* '' */
.mp-location:before { content: '\e840'; } /* '' */
.mp-chrome:before { content: '\e841'; } /* '' */
.mp-location-1:before { content: '\e842'; } /* '' */
.mp-ok-circle:before { content: '\e843'; } /* '' */
.mp-ok-circled:before { content: '\e844'; } /* '' */
.mp-cancel:before { content: '\e845'; } /* '' */
.mp-cancel-1:before { content: '\e846'; } /* '' */
.mp-plus-circle:before { content: '\e847'; } /* '' */
.mp-cw:before { content: '\e848'; } /* '' */
.mp-ccw:before { content: '\e849'; } /* '' */
.mp-calendar:before { content: '\e84a'; } /* '' */
.mp-download-2:before { content: '\e84b'; } /* '' */
.mp-edit:before { content: '\e84c'; } /* '' */
.mp-edit-1:before { content: '\e84d'; } /* '' */
.mp-info:before { content: '\e84e'; } /* '' */
.mp-map-preview:before { content: '\e84f'; } /* '' */
.mp-map-customize:before { content: '\e850'; } /* '' */
.mp-map-explore:before { content: '\e851'; } /* '' */
.mp-logout:before { content: '\e852'; } /* '' */
.mp-sign-in:before { content: '\e853'; } /* '' */
.mp-sign-up:before { content: '\e854'; } /* '' */
.mp-move-icon:before { content: '\e855'; } /* '' */
.mp-tm-dashboard:before { content: '\e856'; } /* '' */
.mp-lock:before { content: '\e857'; } /* '' */
.mp-vertical-view:before { content: '\e858'; } /* '' */
.mp-horizontal-view:before { content: '\e859'; } /* '' */
.mp-upload-cloud:before { content: '\e85a'; } /* '' */
.mp-calendar-1:before { content: '\e85b'; } /* '' */
.mp-sort-alphabet:before { content: '\e85c'; } /* '' */
.mp-mail:before { content: '\e85d'; } /* '' */
.mp-sort-numeric:before { content: '\e85e'; } /* '' */
.mp-lock-open-filled:before { content: '\e85f'; } /* '' */
.mp-resize-full-alt-1:before { content: '\e860'; } /* '' */
.mp-resize-small:before { content: '\e861'; } /* '' */
.mp-ok:before { content: '\e862'; } /* '' */
.mp-download-1:before { content: '\f02e'; } /* '' */
.mp-mobile:before { content: '\f034'; } /* '' */
.mp-print-2:before { content: '\f087'; } /* '' */
.mp-certificate:before { content: '\f0a3'; } /* '' */
.mp-download-cloud:before { content: '\f0ed'; } /* '' */
.mp-doc-text:before { content: '\f0f6'; } /* '' */
.mp-plus-squared:before { content: '\f0fe'; } /* '' */
.mp-doc-text-inv:before { content: '\f15c'; } /* '' */
.mp-trash:before { content: '\f1f8'; } /* '' */
.mp-user-plus:before { content: '\f234'; } /* '' */
.mp-television:before { content: '\f26c'; } /* '' */
.mp-map-o:before { content: '\f278'; } /* '' */
.mp-map:before { content: '\f279'; } /* '' */