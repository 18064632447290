@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600);
body,
html {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

.mb-1 {
  margin-bottom: 0.2rem;
}
.mt-1 {
  margin-top: 0.2rem;
}
.ml-1 {
  margin-left: 0.2rem;
}
.mr-1 {
  margin-right: 0.4rem;
}
.mb-2 {
  margin-bottom: 0.4rem;
}
.mt-2 {
  margin-top: 0.4rem;
}
.ml-2 {
  margin-left: 0.4rem;
}
.mr-2 {
  margin-right: 0.4rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mt-3 {
  margin-top: 1rem;
}
.ml-3 {
  margin-left: 1rem;
}
.mr-3 {
  margin-right: 1rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.mt-4 {
  margin-top: 2rem;
}
.ml-4 {
  margin-left: 2rem;
}
.mr-4 {
  margin-right: 2rem;
}

.heading {
  font-size: 1.2rem;
  margin-top: 0;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

/* Whole Page Container */

.App {
  position: relative;
}

/* Header */

.eha-logo img {
  width: 100px;
}

.app-logo img {
  height: 46px;
}

.app-logo .svg-logo {
  height: 38px;
}

.app-header a {
  color: #ffffff;
  text-decoration: none;
}

/* Tabs */

.tabs-container .tab-item::after {
  height: 5px;
}

.tabs-container .tab-item {
  padding: 1.6rem 2rem 1.2rem;
  width: 22rem;
}

.tabs-container .tab-item .tab-label {
  font-weight: bold;
}

.tab-item i {
  font-size: 1.6rem;
}

.tabs-container .tabs-menu {
  height: 6rem;
  padding: 3px 2rem 0;
}

/* Layout */

.tabs-container .tab-content {
  padding: 0;
}

.tabs-container .tab-bar {
  border-width: 0 0 1px 0;
  height: 6rem;
  flex: 0 0 100%;
  position: relative;
  /* z-index: 1; */
  background: #ffffff;
  padding: 5px 15px;
}

.tab-content .side-bar {
  min-height: calc(100vh - 18rem);
  position: relative;
  z-index: 0;
  display: flex;
  flex-flow: column;
}

.tab-content .map-preview-link {
  display: flex;
  justify-content: space-between;
}

.side-bar.left {
  border-width: 0 1px 0 0;
  box-shadow: 5px 0 6px -5px rgba(0, 0, 0, 0.08);
  z-index: 1;
}

.side-bar.right {
  border-width: 0 0 0 1px;
  box-shadow: -5px 0 6px -5px rgba(0, 0, 0, 0.08);
}

/* Scrollbar Box */

.App .scroll-map-box {
  max-height: 700px;
  overflow-y: scroll;
  padding-bottom: 3em;
  scrollbar-color: #3F4570 #C2D2E4;
}

.scroll-map-box::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.scroll-map-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #f5f5f5;
  border-radius: 10px;
  margin: 3em 0;
}

/* Handle */
.scroll-map-box::-webkit-scrollbar-thumb {
  background: #3F4570;
  border-radius: 50px;
}

/* Handle on hover */
.scroll-map-box::-webkit-scrollbar-thumb:hover {
  background: #3F4570;
  cursor: pointer;
}

/* Map Preview Page & Map Explorer Page */

.datepicker {
  margin: 10px 0;
}

.DayPickerInput input {
  font-size: 13px;
}

.download-btn {
  float: right;
}

.App .template-t-box {
  display: flex;
}

.table-placement {
  padding-left: 20px;
}

.map-preview-box {
  background: #F1F8FD;
  padding: 20px;
}

.side-bar .side-bar-header {
  border-width: 0 0 1px 0;
}

.side-bar .side-bar-header h5 {
  margin: 0;
}

.side-bar .side-bar-dropdown,
.side-bar .side-bar-item {
  border-bottom: 1px solid #EDF7FF;
}

.side-bar .side-bar-dropdown:hover {
  background: #EFF4F8;
}

.side-bar-dropdown .icon {
  font-size: 1.6rem;
  color: #62707B;
  display: flex;
  align-items: center;
}

.side-bar .dropdown-container {
  display: none;
  position: absolute;
  left: 100%;
  top: -1px;
  width: 26rem;
  min-height: 100%;
  background: #ffffff;
  box-shadow: 5px 0 6px -5px rgba(0, 0, 0, 0.08);
}

.side-bar-button {
  padding:1.4rem 2rem;
}
.side-bar-button > button {
  display: none;
}

.edp-link {
  margin:1.4rem 2rem;
}

.edp-button {
  width: 100%;
}

@-webkit-keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.side-bar-dropdown:hover .dropdown-container {
  display: block;
  -webkit-animation: fade-in 0.5s;
          animation: fade-in 0.5s;
}

.customTextItem {
  align-items: center;
  border-radius: 3px;
}
.customTextItem:hover {
  background: #F1F8FD;
}

.customTextItem .color {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 3px;
}

/* Checkboxes */

.dropdown-container .simple-checkbox {
  padding: 0.1rem 0;
  margin: 0.1rem 0;
  width: 100%;
}

.side-p-container {
  margin-bottom: auto;
}

.side-p-container a {
  margin-left: 0;
}

.dropdown-container .simple-checkbox .pretty,
.side-p-container .simple-checkbox .pretty {
  display: block;
  margin: 0;
}

.dropdown-container .simple-checkbox .p-icon label,
.side-p-container .simple-checkbox .p-icon label {
  font-weight: 600;
  margin: 0 -0.6rem;
  padding: 0.6rem 1.2rem;
  border-radius: 3px;
  display: block;
  transition: background 0.2s;
  color: #62707B;
}

.datalayer-toggle {
  position: relative;
}

.datalayer-toggle .simple-checkbox:nth-child(2) {
  position: absolute;
  width: auto;
  top: 0.6rem;
  right: 1.2rem;
}

.dropdown-container .simple-checkbox input:checked~.state label,
.side-p-container .simple-checkbox input:checked~.state label {
  background: #EDF7FF;
}

.dropdown-container .simple-checkbox .p-switch[disabled] .state:before {
  border-color: #cacaca;
}

.dropdown-container .simple-checkbox .p-switch[disabled] input:checked~.state.p-primary:before {
  background-color: #cacaca;
  border-color: #cacaca;
}

.dropdown-container .simple-checkbox .p-switch[disabled] label:after {
  background: #cacaca;
}

.side-p-container .simple-checkbox {
  padding: 0.1rem 0;
  margin: 0.1rem 0;
  width: 100%;
}

/* Modal Map Details */

.img-preview {
  max-width: 100%;
  height: auto;
}

/* Map Preview Page */

.map-container {
  padding: 0.2rem;
  margin-bottom: 1rem;
  display: block;
}

.map-container h4 {
  font-size: 1.5rem;
  margin: 0.1rem 0;
  font-weight: 300;
}

.map-container h4 b {
  font-weight: 700;
}

.map-container p {
  font-weight: 200;
  margin: 0.1rem 0 0.6rem;
}

.map-preview-header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.client-logo {
  max-width: 150px;
  max-height: 45px;
  object-fit: contain;
  object-position: 100% 0%;
}

.map-box {
  border: 0;
  height: 500px;
}

.scale-control div {
  box-shadow: none !important;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.legend-box {
  display: flex;
  flex-direction: row;
  border: 1px solid #f5f5f5;
  padding: 0.5rem;
  margin-top: 0.4rem;
  flex-wrap: wrap
}

.legend-box-landscape,
.legend-box-landscape .legend-span,
.legend-box-landscape .map-info {
  flex-direction: column;
}

.legend-box-landscape {
  border: 0;
  justify-content: flex-end;
}

.legend-box-landscape .legend-list {
  flex-basis: 0%;
  background: #f5f5f5;
  padding: 10px 5px;
  border: 1px solid #eeeeee;
}

.legend-box-landscape .legend-span .data-layer-span {
  width: 100%;
}

.legend-box-landscape .map-text {
  width: 100%;
  padding: 5px;
}

.legend-box-landscape .map-logo {
  width: 35%;
  align-items: center;
  flex-direction: row;
}

.legend-box-landscape .map-logo img {
  display: inline;
}

.legend-box-landscape .map-info {
  align-items: center;
}

.map-container .legend-box p {
  margin: 0.1rem 0 0.5rem;
  font-weight: 500;
}

.legend-list {
  flex-basis: 70%;
}

.map-info {
  flex-basis: 30%;
}

.legend-span {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 1rem;
}

.legend-span .data-layer-span {
  display: inline-block;
  width: 33.3%;
  margin-bottom: 0.9rem;
}

.legend-span span i {
  padding-right: 0.5rem;
}

.map-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 1.6rem;
}

.map-text {
  width: 85%;
}

.map-text p {
  font-size: 0.5rem;
  color: #333333;
}

.map-text p span {
  display: inline-block;
  padding: 0 0.2rem;
}

.map-logo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 15%;
  align-items: flex-end
}

.map-icon-img {
  height: 2.4rem;
}

.table-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.table-box table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.table-box thead {
  background: #333333;
  color: #ffffff;
  text-transform: uppercase;
}

.table-box thead th {
  padding: 0.4rem 0;
  text-align: left;
}

.table-box thead th:first-child {
  text-align: center;
}

.table-box tbody tr td:first-child {
  text-align: center;
}

.table-box tbody tr td {
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #333333;
}

.table-box tbody tr:nth-child(even) {
  background: #F1F8FD;
}

.data-column-box {
  background: #F1F8FD;
  padding: 0.7rem 0.5rem;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(55, 33, 116, 0.05);
  border-radius: 3px;
  margin-bottom: 1rem;
  cursor: grab;
}

.data-column-box .drag-button-box {
  margin-left: auto;
}

.data-column-box i {
  font-size: 1.5rem;
  margin-right: 0.3rem;
}

.edit-column-select {
  margin-top: 10px;
}

/* Loader Styling */

.app-wrapper .loader-wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

/* Autosuggest Styling */

.textbox-autosuggest {
  min-width: 400px;
}

.textbox-autosuggest .react-autosuggest__input {
  font-size: 12px;
}

/* Sidebar Label Count */

.label-box {
  display: flex;
  flex-direction: row;
}

.label-count {
  background: #DCD6F7;
  color: #3F4570;
  font-size: 10px;
  padding: 5px;
  font-weight: 600;
  width: 28px;
  max-height: 25px;
  border-radius: 50%;
  text-align: center;
}

/* File Upload */

.file-upload {
  color: #999999;
  font-family: inherit;
}

.download-btn .pad-l-10 {
  padding-left: 10px;
}

/* Datalayer legends */

.legend_key {
  display: inline-block;
  height: 7px;
  width: 15px;
  margin-right: 4px;
  margin-left: 3px;
}

/*
   Animation example, for spinners
*/
.animate-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@font-face {
  font-family: 'map-composer';
  src: url(/static/media/map-composer.71d28b03.eot);
  src: url(/static/media/map-composer.71d28b03.eot#iefix) format('embedded-opentype'),
       url(/static/media/map-composer.3430c884.woff2) format('woff2'),
       url(/static/media/map-composer.2591043c.woff) format('woff'),
       url(/static/media/map-composer.2ebe30b1.ttf) format('truetype'),
       url(/static/media/map-composer.f1793e1b.svg#map-composer) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'map-composer';
    src: url('../font/map-composer.svg?41716266#map-composer') format('svg');
  }
}
*/
 
 [class^="mp-"]:before, [class*=" mp-"]:before {
  font-family: "map-composer";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.mp-gas-station:before { content: '\e800'; } /* '' */
.mp-environment:before { content: '\e801'; } /* '' */
.mp-hospital-b:before { content: '\e802'; } /* '' */
.mp-pharmacy-2:before { content: '\e803'; } /* '' */
.mp-police-station-:before { content: '\e804'; } /* '' */
.mp-police-station-b:before { content: '\e805'; } /* '' */
.mp-postal-1:before { content: '\e806'; } /* '' */
.mp-school:before { content: '\e807'; } /* '' */
.mp-shop:before { content: '\e808'; } /* '' */
.mp-religious-school:before { content: '\e809'; } /* '' */
.mp-religious-school-1:before { content: '\e80a'; } /* '' */
.mp-pharmacy:before { content: '\e80b'; } /* '' */
.mp-print:before { content: '\e80c'; } /* '' */
.mp-print-1:before { content: '\e80d'; } /* '' */
.mp-college-15:before { content: '\e80e'; } /* '' */
.mp-police-15:before { content: '\e80f'; } /* '' */
.mp-place-of-worship-15:before { content: '\e810'; } /* '' */
.mp-post-15:before { content: '\e811'; } /* '' */
.mp-police:before { content: '\e812'; } /* '' */
.mp-market:before { content: '\e813'; } /* '' */
.mp-prison-15:before { content: '\e814'; } /* '' */
.mp-town-hall:before { content: '\e815'; } /* '' */
.mp-fuel:before { content: '\e816'; } /* '' */
.mp-hospital-15:before { content: '\e817'; } /* '' */
.mp-pharmacy-15:before { content: '\e818'; } /* '' */
.mp-prison:before { content: '\e819'; } /* '' */
.mp-download:before { content: '\e81a'; } /* '' */
.mp-map-1:before { content: '\e81b'; } /* '' */
.mp-search:before { content: '\e81c'; } /* '' */
.mp-plus:before { content: '\e81d'; } /* '' */
.mp-doc:before { content: '\e81e'; } /* '' */
.mp-print-3:before { content: '\e81f'; } /* '' */
.mp-laptop:before { content: '\e820'; } /* '' */
.mp-hospital:before { content: '\e821'; } /* '' */
.mp-worship-house:before { content: '\e822'; } /* '' */
.mp-building:before { content: '\e823'; } /* '' */
.mp-postal:before { content: '\e824'; } /* '' */
.mp-globe:before { content: '\e825'; } /* '' */
.mp-right:before { content: '\e826'; } /* '' */
.mp-left:before { content: '\e827'; } /* '' */
.mp-up:before { content: '\e828'; } /* '' */
.mp-down:before { content: '\e829'; } /* '' */
.mp-left-open:before { content: '\e82a'; } /* '' */
.mp-down-open:before { content: '\e82b'; } /* '' */
.mp-right-open:before { content: '\e82c'; } /* '' */
.mp-up-open:before { content: '\e82d'; } /* '' */
.mp-right-1:before { content: '\e82e'; } /* '' */
.mp-left-1:before { content: '\e82f'; } /* '' */
.mp-folder:before { content: '\e830'; } /* '' */
.mp-folder-1:before { content: '\e831'; } /* '' */
.mp-user-add:before { content: '\e832'; } /* '' */
.mp-user:before { content: '\e833'; } /* '' */
.mp-user-outline:before { content: '\e834'; } /* '' */
.mp-down-dir:before { content: '\e835'; } /* '' */
.mp-up-dir:before { content: '\e836'; } /* '' */
.mp-left-dir:before { content: '\e837'; } /* '' */
.mp-spin5:before { content: '\e838'; } /* '' */
.mp-spin6:before { content: '\e839'; } /* '' */
.mp-right-dir:before { content: '\e83a'; } /* '' */
.mp-home:before { content: '\e83b'; } /* '' */
.mp-loop:before { content: '\e83c'; } /* '' */
.mp-arrows-cw:before { content: '\e83d'; } /* '' */
.mp-floppy:before { content: '\e83e'; } /* '' */
.mp-floppy-1:before { content: '\e83f'; } /* '' */
.mp-location:before { content: '\e840'; } /* '' */
.mp-chrome:before { content: '\e841'; } /* '' */
.mp-location-1:before { content: '\e842'; } /* '' */
.mp-ok-circle:before { content: '\e843'; } /* '' */
.mp-ok-circled:before { content: '\e844'; } /* '' */
.mp-cancel:before { content: '\e845'; } /* '' */
.mp-cancel-1:before { content: '\e846'; } /* '' */
.mp-plus-circle:before { content: '\e847'; } /* '' */
.mp-cw:before { content: '\e848'; } /* '' */
.mp-ccw:before { content: '\e849'; } /* '' */
.mp-calendar:before { content: '\e84a'; } /* '' */
.mp-download-2:before { content: '\e84b'; } /* '' */
.mp-edit:before { content: '\e84c'; } /* '' */
.mp-edit-1:before { content: '\e84d'; } /* '' */
.mp-info:before { content: '\e84e'; } /* '' */
.mp-map-preview:before { content: '\e84f'; } /* '' */
.mp-map-customize:before { content: '\e850'; } /* '' */
.mp-map-explore:before { content: '\e851'; } /* '' */
.mp-logout:before { content: '\e852'; } /* '' */
.mp-sign-in:before { content: '\e853'; } /* '' */
.mp-sign-up:before { content: '\e854'; } /* '' */
.mp-move-icon:before { content: '\e855'; } /* '' */
.mp-tm-dashboard:before { content: '\e856'; } /* '' */
.mp-lock:before { content: '\e857'; } /* '' */
.mp-vertical-view:before { content: '\e858'; } /* '' */
.mp-horizontal-view:before { content: '\e859'; } /* '' */
.mp-upload-cloud:before { content: '\e85a'; } /* '' */
.mp-calendar-1:before { content: '\e85b'; } /* '' */
.mp-sort-alphabet:before { content: '\e85c'; } /* '' */
.mp-mail:before { content: '\e85d'; } /* '' */
.mp-sort-numeric:before { content: '\e85e'; } /* '' */
.mp-lock-open-filled:before { content: '\e85f'; } /* '' */
.mp-resize-full-alt-1:before { content: '\e860'; } /* '' */
.mp-resize-small:before { content: '\e861'; } /* '' */
.mp-ok:before { content: '\e862'; } /* '' */
.mp-download-1:before { content: '\f02e'; } /* '' */
.mp-mobile:before { content: '\f034'; } /* '' */
.mp-print-2:before { content: '\f087'; } /* '' */
.mp-certificate:before { content: '\f0a3'; } /* '' */
.mp-download-cloud:before { content: '\f0ed'; } /* '' */
.mp-doc-text:before { content: '\f0f6'; } /* '' */
.mp-plus-squared:before { content: '\f0fe'; } /* '' */
.mp-doc-text-inv:before { content: '\f15c'; } /* '' */
.mp-trash:before { content: '\f1f8'; } /* '' */
.mp-user-plus:before { content: '\f234'; } /* '' */
.mp-television:before { content: '\f26c'; } /* '' */
.mp-map-o:before { content: '\f278'; } /* '' */
.mp-map:before { content: '\f279'; } /* '' */
