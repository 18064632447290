@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600);

body,
html {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

.mb-1 {
  margin-bottom: 0.2rem;
}
.mt-1 {
  margin-top: 0.2rem;
}
.ml-1 {
  margin-left: 0.2rem;
}
.mr-1 {
  margin-right: 0.4rem;
}
.mb-2 {
  margin-bottom: 0.4rem;
}
.mt-2 {
  margin-top: 0.4rem;
}
.ml-2 {
  margin-left: 0.4rem;
}
.mr-2 {
  margin-right: 0.4rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mt-3 {
  margin-top: 1rem;
}
.ml-3 {
  margin-left: 1rem;
}
.mr-3 {
  margin-right: 1rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.mt-4 {
  margin-top: 2rem;
}
.ml-4 {
  margin-left: 2rem;
}
.mr-4 {
  margin-right: 2rem;
}

.heading {
  font-size: 1.2rem;
  margin-top: 0;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}
